<template>
    <div class='brief' v-loading="loading">
        <div class="brief">
            <div class="image">
                <el-image style="width: 100%; height: 100%"
                    :src="info.img_url" fit="contain"></el-image>
            </div>
        </div>
        <div class="content">
            <div class="title">协会简介</div>
            <div class="text" v-html="info.content"></div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            info: {},
            total: 0
        };
    },
    mounted() {
        this.getList()
    },
    methods: {
        async getList() {
            this.loading = true
            let params = {
                content_type: 9,
                source: this.PJSource,
                nopage: 0,
                limit: 1,
                start: 0,
                is_show: 1,
                language: this.LOCALE == "en" ? 1 : 2,
            };
            let res = await this.$store.dispatch(
                "API_index/getStaticContent",
                params
            );
            if (res.success) {
                this.info = res.data[0]||{};
                this.total = res.total
                this.loading = false
            }
        },
    },
};
</script>

<style lang='less' scoped>
.brief {
    display: flex;

    .image {
        width: 549px;
        height: 342px;
        margin-right: 47px;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

.content {
    font-size: 18px;
    color: #333;
    line-height: 36px;

    .title {
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        margin-bottom: 17px;
    }

    .text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 8;
        word-break: break-all;
        height: 290px;
    }
}
</style>