<template>
  <div class="specialCampaign">
    <div class="title">
      <div class="txt" @click="isTit()">{{title}}
        <!-- <i style="font-size:14px;color:#0E17AE;" :class="icon ?'el-icon-arrow-up' : 'el-icon-arrow-down'"></i> -->
      </div>
      <!-- <div class="txtHide" v-if="icon">
        <div class="txt" :class="title == '专项服务' ? 'active' : ''" @click="changeTit(82)">专项服务</div>
        <div class="txt" :class="title == '行业联谊' ? 'active' : ''" @click="changeTit(83)">行业联谊</div>
      </div> -->
      <div class="more" @click="goMore">查看更多</div>
    </div>
    <div class="list" v-loading="loading">
      <div class="item clearfix" v-for="(it, index) in specialCampaignList"
          :key="index"> 
        <div 
          class="txt fl"
          @click="goSpecialCampaign(it.id)"
          :title="it.title"
        >
          {{ it.title }}
        </div>
        <div class="time fr">{{ it.created_time | secondFormat("LL") }}</div>
      </div>
      <noDataImg class="noDataImg" v-if="!loading && specialCampaignList.length == 0"></noDataImg>
    </div>
  </div>
</template>

<script>
export default {
  name: "specialCampaign",

  data() {
    return {
      title: '专项活动',
      icon: false,
      loading: false,
      specialCampaignList: [],
      type: 91, // 82
      total: 0,
    };
  },

  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      this.loading = true
      let params = {
        content_type: this.type,
        source: this.PJSource,
        nopage: 0,
        limit: 6,
        start: 0,
        is_show: 1,
        language: this.LOCALE == "en" ? 1 : 2,
      };
      let res = await this.$store.dispatch(
        "API_index/getStaticContent",
        params
      );
      if (res.success) {
        this.specialCampaignList = res.data;
        this.total = res.total
        this.loading = false
      }
    },
    goSpecialCampaign(id) {
      this.$router.push(`/detail?id=${id}`);
    },
    isTit() {
      this.icon = !this.icon
    },
    changeTit(type) {
      if(type == 82) {
        this.title = "专项服务"
        this.icon = !this.icon
        this.type = type
        this.getList()
      } else {
        this.title = "行业联谊"
        this.icon = !this.icon
        this.type = type
        this.getList()
      }
    },
    goMore() {
      this.$router.push("/activitys?content_type="+this.type);
    },
  },
};
</script>

<style lang="less" scoped>
.specialCampaign {
  position: relative;
  width: 580px;
  height: 281px;
  .title {
    margin-bottom: 23px;
    display: flex;
    position: relative;
    cursor: pointer;
    user-select: none;
    justify-content: space-between;
    align-items: center;
    .txt {
      font-size: 24px;
      line-height: 24px;
      font-weight: 400;
      color: #0E17AE;
    }
    .txtHide {
      position: absolute;
      left: 0;
      top: 43px;
      z-index: 999;
      width: 260px;
      height: 120px;
      background: #FFFFFF;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      overflow: hidden;
      .active {
        background: #0E17AE !important;
        color: #fff !important;
      }
      .txt {
        width: 260px;
        height: 60px;
        text-align: center;
        background: #fff;
        color: #333333; 
        line-height: 60px;
        font-size: 20px;
      }
      .txt:hover {
        background: #ecedf9;
        color:  #666666;
      }
    }
    .more {
      font-size: 14px;
      font-weight: 400;
      color: #1018A1;
      opacity: 0.8;
      cursor: pointer;
    }
  }
  .list {
    height: 234px;
    position: relative;
    .item {
      width: 580px;
      height: 14px;
      margin-bottom: 30px;
      .txt {
        width: 262px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: #333;
        font-size: 14px;
        line-height: 14px;
      }
      .txt:hover {
        color: #0E17AE;
        cursor: pointer;
      }
      .time {
        font-size: 14px;
        color: #999999;
        line-height: 14px;

      }
    }
    .item:nth-child(1) {
      .txt {
        width: 388px;
      }
    }
  }
  
}
</style>