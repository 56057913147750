<template>
    <div class="friendlyLink">
        <div class="txt">友情链接：</div>
        <div class="imgs">
            <a class="item" v-for="(item, index) in imgList" :key="index" :href="item.url" target="_blank">
                <img :src="item.img_url" alt="">
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'friendlyLink',

    data() {
        return {
            imgList: [
                {
                    url: 'https://www.siffa.org/',
                    img_url: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/jzs/siffa.png'
                },
                {
                    url: 'https://www.tiffa.com.cn/',
                    img_url: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/jzs/tiffa.png'
                },
                {
                    url: 'https://ailaworld.com/',
                    img_url: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/jzs/ailainside.png'
                },
                {
                    url: 'http://www.cifa-china.com/cn/index.html',
                    img_url: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/jzs/cifa.png'
                },
                {
                    url: 'https://henanexpo56.com/',
                    img_url: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/jzs/expozz.png'
                }
            ],
        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style lang="less" scoped>
.friendlyLink {
    height: 175px;
    background: #fff;

    .txt {
        margin-top: 59px;
        font-size: 24px;
        font-weight: 400;
        color: #999999;
        line-height: 24px;
    }

    .imgs {
        margin-top: 39px;
        display: flex;
        flex-wrap: wrap;

        .item {
            width: 132px;
            height: 66px;
            background: #FFFFFF;
            // border: 1px solid #FFFFFF;
            // box-shadow: 0px 4px 16px 0px rgba(171, 171, 171, 0.25);
            border-radius: 4px;
            overflow: hidden;
            margin-right: 23px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>