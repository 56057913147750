<template>
    <div>
        <div class="tab">
            <div class="top">
                <div class="classfly">
                    <div @click="tabChange(2)" :class="['tabItem', type == 2 ? 'tabColor' : '']">协会动态</div>
                    <div @click="tabChange(89)" :class="['tabItem', type == 89 ? 'tabColor' : '']">行业风采</div>
                </div>
                <div class="more" @click="goMore">查看更多</div>
            </div>
            <div class="line"></div>
        </div>
        <div style="position: relative;height: 400px;">
            <div class="bottom" v-if="total !== 0">
                <div class="left" @click="toDetail(one.id)">
                    <el-image :src="one.img_url" fit="cover" class="image"></el-image>
                    <div class="tit_box">
                        <div class="txt">{{one.title}}</div>
                        <div class="time">{{one.created_time | secondFormat("LL")}}</div>
                    </div>
                </div>
    
                <div class="right">
                    <div class="item" v-for="item in list" :key="item.id" @click="toDetail(item.id)">
                        <div class="tit">{{ item.title }}</div>
                        <div class="time">{{item.created_time | secondFormat("LL")}}</div>
                    </div>
                </div>
            </div>
            <noDataImg class="noDataImg" v-if="total == 0"></noDataImg>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            type: 2,
            loading: false,
            list: [],
            one: {},
            total: 0
        };
    },
    mounted() {
        this.getList()
    },
    methods: {
        goMore() {
            this.$router.push("/activitys?content_type="+this.type);
        },
        toDetail(id) {
            this.$router.push(`/detail?id=${id}`);
        },
        tabChange(type) {
            this.type = type
            this.getList()
        },
        async getList() {
            this.loading = true
            let params = {
                content_type: this.type,
                source: this.PJSource,
                nopage: 0,
                limit: 6,
                start: 0,
                is_show: 1,
                language: this.LOCALE == "en" ? 1 : 2,
            };
            let res = await this.$store.dispatch(
                "API_index/getStaticContent",
                params
            );
            if (res.success) {
                this.one = res.data.splice(0, 1)[0]
                this.list = res.data;
                this.total = res.total
                this.loading = false
            }
        },
    },
};
</script>

<style lang='less' scoped>
.tab {
    margin-bottom: 20px;

    .top {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .classfly {
            display: flex;
            font-size: 24px;
            font-weight: 400;
            color: #104BDD;

            .tabItem {
                margin-right: 60px;
                cursor: pointer;
            }

            .tabColor {
                color: #104BDD;
            }
        }

        .more {
            font-size: 14px;
            font-weight: 400;
            color: #104BDD;
            cursor: pointer;
        }
    }

    .line {
        height: 1px;
        background: #EEEEEE;
        margin-top: 20px;
    }
}

.bottom {
    display: flex;
    justify-content: space-between;
    .left {
        position: relative;
        width: 600px;
        height: 400px;
        cursor: pointer;
        .image {
            width: 100%;
            height: 100%;
        }
        .tit_box {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 600px;
            height: 80px;
            background-color: rgba(6, 58, 190, 0.8);
            padding: 20px;
            .txt {
                color: #fff;
                font-size: 14px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            .time {
                color: #fff;
                font-size: 14px;
                margin-top: 5px;
            }
        }
    }

    .right {
        width: 560px;

        .item {
            margin-top: 15px;
            padding-bottom: 15px;
            border-bottom: 1px solid #EEEEEE;
            cursor: pointer;
            .tit {
                width: 330px;
                font-size: 14px;
                color: #333;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                &:hover {
                    color: #0E17AE;
                }
            }
            .time {
                margin-top: 11px;
                font-size: 14px;
                color: #999999;
            }
        }
    }

}
</style>