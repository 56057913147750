<template>
    <div class='contact'>
        <div class="content_area">
            <div class="us">
                <h2>联系我们</h2>
                <div class="content">
                    <div>联系人：河北省国际货运代理协会秘书处</div>
                    <div>电话： 0311-87708676</div>
                    <div>Email： hebifa@hebifa.com</div>
                    <div>地址：石家庄市新华区泰华街29号天林大厦1512室</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },
    components: {

    },
    mounted() {

    },
    methods: {

    },
};
</script>

<style lang='less' scoped>

.contact {
    width: 1200px;
    margin: 0 auto;
    height: 610px;
    background: url('https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/jzs/contactBg1.png');
    background-repeat: no-repeat;
    background-size: auto 100%;
    overflow: hidden;

    .us {
        margin-top: 98px;
        margin-left: 800px;
        h2 {
            font-size: 28px;
            font-weight: bold;
            color: #333333;
            margin-bottom: 35px;
        }
        .content {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 32px;
        }
    }
}
</style>