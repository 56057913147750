<template>
    <div class="container">
        <carouselBanner paddingTop="31.2%" :bannerType="1"></carouselBanner>
        
        <div class="content_area">
            <!-- 协会简介 -->
            <div style="margin-bottom: 75px;">
                <societyBrief></societyBrief>
            </div>
            <!-- 通知公告、新闻资讯、货代知识 -->
            <div class="flexBox">
                <Notification></Notification>
                <NewsExpress></NewsExpress>
                <ForwarderKnowledge></ForwarderKnowledge>
            </div>
            <!-- 协会动态、行业风采 -->
            <div style="margin-bottom: 80px;">
                <tabClassfly></tabClassfly>
            </div>
            <!-- 行业管理、专项活动 -->
            <div class="flexBox" style="margin-bottom: 48px;">
                <TalentZone></TalentZone>
                <SpecialCampaign></SpecialCampaign>
            </div>
        </div>
        <!-- 联系我们 -->
        <div style="margin-bottom: 50px;">
            <contactUs></contactUs>
        </div>
        <!-- 友情链接 -->
        <div class="content_area">
            <FriendlyLink></FriendlyLink>
        </div>
        <!-- 申请入会 -->
        <!-- <div class="img">
            <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/jzs/initiation.gif" alt="" @click="goMemberShip">
        </div> -->
    </div>
</template>

<script>

import carouselBanner from "@/baseComponents/carouselBanner"
import NewsExpress from "~jzs/components/index/home/newsExpress"
import Notification from "~jzs/components/index/home/notification"
import ForwarderKnowledge from "~jzs/components/index/home/forwarderKnowledge"
import Activity from '~jzs/components/index/home/activity'
import TalentZone from "~jzs/components/index/home/talentZone"
import SpecialCampaign from "~jzs/components/index/home/specialCampaign"
import Education from "~jzs/components/index/home/education";
import FriendlyLink from "~jzs/components/index/home/friendlyLink";
import societyBrief from "~jzs/components/index/home/societyBrief";
import contactUs from "~jzs/components/index/home/contactUs";
import tabClassfly from "../components/index/home/tabClassfly.vue";

export default {
    metaInfo() {
        return {
            title: '河北省国际货运代理协会',
        };
    },
    props: {},
    components: {
        Notification,
        NewsExpress,
        ForwarderKnowledge,
        Activity,
        carouselBanner,
        TalentZone,
        SpecialCampaign,
        Education,
        FriendlyLink,
        societyBrief,
        contactUs,
        tabClassfly
    },
  data () {
   return {}
  },
  computed: {},
  watch: {},
  methods: {
      goMemberShip() {
          this.$router.push("/memberShip");
      }

  },

  created () {
  },
  mounted () {
  },
}
</script> 

<style scoped lang="less">
    .flexBox {
        display: flex;
        justify-content: space-between;
    }
    .container {
        background-color: #fff;
        
        .cooperation {
            width: 100%;
            height: 612px;
            background: url(https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/jzs/bg_educational.png) no-repeat 100% 100%;
        }
        .img {
            width: 206px;
            height: 188px;
            position:fixed;
            right: 0;
            bottom: 45%;  
            z-index: 9999;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }

</style>