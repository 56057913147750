import { render, staticRenderFns } from "./newsExpress.vue?vue&type=template&id=10c71dea&scoped=true&"
import script from "./newsExpress.vue?vue&type=script&lang=js&"
export * from "./newsExpress.vue?vue&type=script&lang=js&"
import style0 from "./newsExpress.vue?vue&type=style&index=0&id=10c71dea&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10c71dea",
  null
  
)

export default component.exports